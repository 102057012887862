import React from "react";
import Grid from "@mui/material/Grid2";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { AboutUsLine } from "../../svgImage";
import styles from "./LandingPage.module.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import mango from "../../assets/mango.jpg";
import red_velvet from "../../assets/red_velvet.jpg";
import Vanila from "../../assets/Vanila.jpg";
import Protein_cookies from "../../assets/Protein-cookies.jpg";
import sandwich_cookie from "../../assets/sandwich_cookie.jpg";
import Caramel_cupcakes from "../../assets/Caramel-cupcakes.jpg";
import chocolate_cupcakes from "../../assets/chocolate-cupcakes.jpg";
import whole_wheat from "../../assets/whole-wheat.jpg";

const MustTryComponent = () => {
  const isMobile = useMediaQuery("(max-width:468px)");
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  const storeLink = process.env.REACT_APP_PRASADAM_STORE_LINK;
  const images = [
    {
      src: mango,
      name: "Mango",
    },
    {
      src: red_velvet,
      name: "Red velvet",
    },
    {
      src: Vanila,
      name: "Vanial",
    },
    {
      src: Protein_cookies,
      name: "Protein cookies",
    },
    {
      src: sandwich_cookie,
      name: "Sandwich cookies",
    },
    {
      src: Caramel_cupcakes,
      name: "Caramel cupcakes",
    },
    {
      src: chocolate_cupcakes,
      name: "Chocolate cupcakes",
    },
    {
      src: whole_wheat,
      name: "Whole wheat",
    },
  ];

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      marginTop="100px"
    >
      <Grid size={{ xs: 12, sm: 10, md: 5, lg: 4 }} padding="25px">
        <Grid
          container
          direction="column"
          rowSpacing={{ xs: "10px", sm: "30px", md: "15px", lg: "40px" }}
        >
          <Grid>
            <Box display="flex" alignItems="center">
              <AboutUsLine width={isMobile && "60px"} />
              <Typography className={styles.Aboutustext} paddingLeft={1}>
                Must-try delights
              </Typography>
            </Box>
          </Grid>
          <Grid>
            <Typography className={styles.AboutUsShortDiscription}>
              Tried, tasted, and loved—explore <br /> our top picks!
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid size={{ xs: 12, sm: 10, md: 5, lg: 4 }} />
      <Grid size={12} marginTop="24px">
        <Carousel
          responsive={responsive}
          centerMode={true}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
        >
          {images?.map((image, index) => (
            <div style={{ margin: 10 }}>
              <CourselCard
                image={image?.src}
                name={image?.name}
                storeLink={storeLink}
              />
            </div>
          ))}
        </Carousel>
      </Grid>
    </Grid>
  );
};

const CourselCard = (props) => {
  const { image, name, storeLink } = props;
  const handlerOrderNow = () => {
    window.location.href = storeLink;
  };
  return (
    <div className={styles.cardContainer}>
      <img src={image} alt={name} className={styles.image} />
      <div className={styles.contentOverlay}>
        <div className={styles.title}>
          <Typography>{name}</Typography>
        </div>
        <button className={styles.button} onClick={handlerOrderNow}>
          Order now
        </button>
      </div>
    </div>
  );
};

export default MustTryComponent;
