import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import LandingPageHeader from "./LandingPageHeader";
import styles from "./LandingPage.module.css";
import prasadamLogo from "../../assets/prasadamLogo.svg";
import mainCoursel1 from "../../assets/mainCoursel1.jpg";
import mainCoursel2 from "../../assets/mainCoursel2.jpg";
import mainCoursel3 from "../../assets/mainCoursel3.jpg";
import mainCoursel4 from "../../assets/mainCoursel4.jpg";
import { Box, Button, Typography } from "@mui/material";
import AboutUsComponent from "./AboutUsComponent";
import MustTryComponent from "./MustTryComponent";
import FooterComponent from "./FooterComponent";

const LandingPage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [mainCoursel1, mainCoursel2, mainCoursel3, mainCoursel4];

  const storeLink = process.env.REACT_APP_PRASADAM_STORE_LINK;

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeout(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
      }, 500);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleOpenStore = () => {
    // window.open(storeLink, '_blank');
    window.location.href = storeLink;
  };

  return (
    <Grid container>
      <Grid size={12}>
        <LandingPageHeader />
      </Grid>
      <Grid size={12} id="home">
        <Grid container>
          <Grid className={styles.leftContent}>
            <Box className={styles.innerContent}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                rowSpacing={4}
              >
                <Grid item>
                  <img src={prasadamLogo} alt="prasadam-logo" />
                </Grid>
                <Grid item>
                  <Typography align="center" className={styles.quotestext}>
                    Each bite, a request for more delight
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    className={styles.orderNowButton}
                    onClick={handleOpenStore}
                  >
                    Order now!
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid className={styles.rightContent}>
            <div className={styles.mainCoursel}>
              <img
                src={images[currentIndex]}
                alt={`Slide ${currentIndex + 1}`}
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid size={12} id="about">
        <AboutUsComponent />
      </Grid>
      <Grid size={12} id="mustTry">
        <MustTryComponent />
      </Grid>
      <Grid size={12} id="contact">
        <FooterComponent />
      </Grid>
    </Grid>
  );
};

export default LandingPage;
