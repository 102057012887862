import React, { useState } from "react";
import { AppBar, Typography, IconButton, Menu, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Grid from "@mui/material/Grid2";
import { useMediaQuery } from "@mui/material";
import styles from "./LandingPage.module.css";

const LandingPageHeader = () => {
  const isMobile = useMediaQuery("(max-width:850px)");
  const [anchorEl, setAnchorEl] = useState(null);
  const storeLink = process.env.REACT_APP_PRASADAM_STORE_LINK;

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" sx={{ background: "transparent" }}>
      <Grid
        container
        justifyContent={isMobile ? "space-between" : "space-around"}
        className={styles.HeaderContainer}
        alignItems="center"
      >
        <Grid>
          <Typography className={styles.logoTextStyle}>
            Shree Prasadam
          </Typography>
        </Grid>
        {isMobile ? (
          <Grid>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleMenuOpen}
              sx={{ color: "white" }}
            >
              <MenuIcon sx={{ color: "#986633" }} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleMenuClose}>
                <a
                  href="#home"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Home
                </a>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <a
                  href="#about"
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    width: "100%",
                  }}
                >
                  About
                </a>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <a
                  href="#mustTry"
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    width: "100%",
                  }}
                >
                  Featured products
                </a>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <a
                  href="#contact"
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    width: "100%",
                  }}
                >
                  Contact us
                </a>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <a
                  href={storeLink}
                  target="_self"
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    width: "100%",
                  }}
                >
                  Order Online
                </a>
              </MenuItem>
            </Menu>
          </Grid>
        ) : (
          <Grid>
            <Grid container justifyContent="space-between" columnGap={4}>
              <Grid>
                <a
                  href="#home"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Typography className={styles.HeaderText}>Home</Typography>
                </a>
              </Grid>
              <Grid>
                <a
                  href="#about"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Typography className={styles.HeaderText}>About</Typography>
                </a>
              </Grid>
              <Grid>
                <a
                  href="#mustTry"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Typography className={styles.HeaderText}>
                    Featured products
                  </Typography>
                </a>
              </Grid>
              <Grid>
                <a
                  href="#contact"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Typography className={styles.HeaderText}>
                    Contact us
                  </Typography>
                </a>
              </Grid>
              <Grid>
                <a
                  href={storeLink}
                  target="_self"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Typography className={styles.HeaderText}>
                    Order Online
                  </Typography>
                </a>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </AppBar>
  );
};

export default LandingPageHeader;
